import Footer from "./Footer";
import GroupPics from "./GroupPics";
import styles from "./OurTeam.module.css";

const directors =
{
  title: "Co-Directors",
  members: [
    {
      name: "Jack Viebrock",
      img: require("../assets/2024/team/jackv.jpg")
    },
    {
      name: "Jack Gauer",
      img: require("../assets/2024/team/jackg.jpg")
    },
  ]
}

const secretary =
{
  title: "Secretary",
  members: [
    {
      name: "Steffi Chen",
      img: require("../assets/2023/team/steffi.png")
    }
  ]
}

const treasurer =
{
  title: "Treasurer",
  members: [
    {
      name: "Prakhar Gupta",
      img: require("../assets/2024/team/prakhar.jpg")
    }
  ]
}

const corporate =
{
  title: "Corporate Directors",
  members: [
    {
      name: "Konark Dhingreja",
      img: require("../assets/2024/team/konark.jpg")
    },
    {
      name: "Megna Biederman",
      img: require("../assets/2024/team/megna.jpg")
    },
    // {
    //   name: "Rohan Vasavada",
    //   img: require("../assets/2023/team/rohan.png")
    // },
    {
      name: "Vinayak Bagdi",
      img: require("../assets/2024/team/vinayak.jpg")
    },
  ]
}

const wit =
{
  title: "Women in Tech Directors",
  members: [
    // {
    //   name: "Niharika ChintalapatiI",
    //   img: require("../assets/2023/team/neha.png")
    // },
    {
      name: "Manogna Rajanala",
      img: require("../assets/2023/team/manogna.png")
    },
  ]
}

const technical =
{
  title: "Technical Directors",
  members: [
    // {
    //   name: "Megna Biederman",
    //   img: require("../assets/2023/team/megna.png")
    // },
    {
      name: "Sneh Chandak",
      img: require("../assets/2024/team/sneh.jpg")
    },
    {
      name: "Justin Chen",
      img: require("../assets/2024/team/justin.jpg")
    }
  ]
}

const competitions =
{
  title: "Competitions Directors",
  members: [
    {
      name: "Ben Blade",
      img: require("../assets/2024/team/ben.jpg")
    },
    {
      name: "Matthew Guibord",
      img: require("../assets/2024/team/matthew.jpg")
    },
  ]
}

const logistics =
{
  title: "Logistics Directors",
  members: [
    {
      name: "Akshat Singh",
      img: require("../assets/2024/team/akshat.jpg")
    },
    {
      name: "Joshua Hur",
      img: require("../assets/2024/team/joshua.jpg")
    },
  ]
}

const social_media =
{
  title: "Social Media Director",
  members: [
    {
      name: "Arcy Cruz",
      img: require("../assets/2024/team/arcy.jpg")
    }
  ]
}

const marketing =
{
  title: "Marketing",
  members: [
    {
      name: "Akshat Singh",
      img: require("../assets/2023/team/akshat.png")
    }
  ]
}


function OurTeam() {
  return (
    <div className={styles.container}>
      <h1>Our Team</h1>
      <div className={styles.row}>
        <GroupPics title={directors.title} data={directors.members} />
      </div>

      <div className={styles.row}>
      <GroupPics title={social_media.title} data={social_media.members} />
        <GroupPics title={treasurer.title} data={treasurer.members} />
      </div>

      <div className={styles.row}>
        <GroupPics title={technical.title} data={technical.members} />
      </div>

      <div className={styles.row}>
        <GroupPics title={corporate.title} data={corporate.members} />
      </div>

      <div className={styles.row}>
        <GroupPics title={competitions.title} data={competitions.members} />
        {/* <GroupPics title={wit.title} data={wit.members} /> */}
      </div>

      <div className={styles.row}>
        <GroupPics title={logistics.title} data={logistics.members} />
        
        {/* <GroupPics title={marketing.title} data={marketing.members} /> */}
      </div>
      <Footer className={styles.footer} />
    </div>
  )
}

export default OurTeam