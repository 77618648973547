import { useState } from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import Footer from "./Footer";
import styles from "./Events.module.css";

const EventCard = ({ title, time, location }) => {
  return (
    <div className={styles.card}>
      <h3>{title}</h3>
      <p>{time}</p>
      <p>{location}</p>
    </div>
  )
}

const data = [
  {
    day: 0,
    events: [
      {
        title: "Coffee Chat - Marvell",
        time: "10 - 11 AM",
        location: "Atrium"
      },
      {
        title: "Opening Ceremony",
        time: "5 - 6 PM",
        location: "ECEB 1013"
      },
      // {
      //   title: "Tech Talk",
      //   time: "5 - 6 PM",
      //   location: "ECEB 1013"
      // },
      {
        title: "Resume Workshop - Marvell ",
        time: "6 - 7 PM",
        location: "ECEB 1013"
      },
      {
        title: "Dinner - Jimmy Johns *",
        time: "7 - 8 PM",
        location: "ECEB 1013"
      },
    ]
  },
  {
    day: 1,
    events: [
      {
        title: "Coffee Chat with Texas Instruments",
        time: "10 - 11 AM",
        location: "ECEB 1000/Atrium"
      },
      {
        title: "Breadboard Workshop",
        time: "4 - 5 PM",
        location: "ECEB 3002"
      },
      {
        title: "Tech Talk - Texas Instruments",
        time: "5 - 6 PM",
        location: "ECEB 3002"
      },
      {
        title: "Meet & Greet Dinner with TI and Marvell",
        time: "6 - 8 PM",
        location: "ECEB 3002"
      }
    ]
  },
  {
    day: 2,
    events: [
      {
        title: "SIGQuantum Workshop",
        time: "4 - 5 PM",
        location: "ECEB 3002"
      },
      {
        title: "Keynote Speaker - Chris Koopmans COO of Marvell ",
        time: "5 - 6 PM",
        location: "ECEB 1002"
      },
      {
        title: "Dinner *",
        time: "6 - 7 PM",
        location: "ECEB 3002"
      },
      {
        title: "Design Competition",
        time: "7 - 10 PM",
        location: "ECEB 3002"
      },

    ]
  },
  {
    day: 3,
    events: [
      {
        title: "Coffee Chat - MISO",
        time: "10 - 11 AM",
        location: "Atrium"
      },
      {
        title: "WIT Lunch and Lighting Talks - MISO & Marvell",
        time: "12 - 1:30 PM",
        location: "ECEB 3002"
      },
      // {
      //   title: "Lunch - Subway *",
      //   time: "1 - 1:30 PM",
      //   location: "ECEB 3002"
      // }
      
      {
        title: "Women in Academia Panel",
        time: "1:30 - 2:30 PM",
        location: "ECEB 3002"
      }
      ,
      {
        title: "Snacks / Swag Pickup",
        time: "4 - 5 PM",
        location: "ECEB 3002"
      }
      ,
      {
        title: "Tech Talk - Jay Hermacinski (MISO)",
        time: "5 - 6 PM",
        location: "ECEB 3002"
      },
      ,
      {
        title: "Dinner - Papa Dels *",
        time: "6 - 7 PM",
        location: "ECEB 3002"
      },
      
      
    ]
  },
  {
    day: 4,
    events: [
      {
        title: "Tech Talk - Professor Lariviere",
        time: "3 - 4 PM",
        location: "ECEB 3002"
      },
      {
        title: "Software Competition",
        time: "4 - 6 PM",
        location: "ECEB 3002"
      },
      {
        title: "Dinner - Mia Za's *",
        time: "6 - 7 PM",
        location: "ECEB 3002"
      },
      
    ]
  },
  {
    day: 5,
    events: [
      {
        title: "Hardware Competition",
        time: "10 AM - 6 PM",
        location: "ECEB 3002"
      },
      
    ]
  },
  // {
  //   day: 6,
  //   events: [
  //     {
  //       title: "High School Day",
  //       time: "9 AM - 2 PM",
  //       location: "ECEB"
  //     },
  //     {
  //       title: "Meet and Greet (Snacks Provided)",
  //       time: "9 - 9:30 AM",
  //       location: "ECEB 3002"
  //     },
  //     {
  //       title: "ECEB Tour",
  //       time: "9:30 AM - 10 AM",
  //       location: "ECEB 3002"
  //     },
  //     {
  //       title: "Coding Workshop 1",
  //       time: "10 - 11 AM",
  //       location: "ECEB 2022/3022"
  //     },
  //     {
  //       title: "Coding Workshop 2",
  //       time: "11 AM - 12 PM",
  //       location: "ECEB 2022/3022"
  //     },
  //     {
  //       title: "Speakers + Lunch - Dominoes",
  //       time: "12 - 1 PM",
  //       location: "ECEB 3002"
  //     },
  //     {
  //       title: "Competitions\n(with Prizes)",
  //       time: "1 - 2 PM",
  //       location: "ECEB 3002"
  //     }
  //   ]
  // },
]

function Events() {
  const [currentDay, setCurrentDay] = useState(0);
  const [eventData, setEventData] = useState(data[0].events);

  const handleClick = (day) => {
    setCurrentDay(day);
    let newData = data.filter((item) => item.day === day)[0].events;
    setEventData();
    setEventData(newData);
  }

  const handleChange = (event) => {
    let day = event.target.value;
    handleClick(day);
  }

  return (
    <div className={styles.container}>
      <h1>Event Schedule</h1>

      <div className={styles.btnContainer}>
        <button onClick={() => handleClick(0)} className={`${styles.dayBtn} ${currentDay === 0 ? styles.active : ''}`}>February 5th</button>
        <button onClick={() => handleClick(1)} className={`${styles.dayBtn} ${currentDay === 1 ? styles.active : ''}`}>February 6th</button>
        <button onClick={() => handleClick(2)} className={`${styles.dayBtn} ${currentDay === 2 ? styles.active : ''}`}>February 7th</button>
        <button onClick={() => handleClick(3)} className={`${styles.dayBtn} ${currentDay === 3 ? styles.active : ''}`}>February 8th</button>
        <button onClick={() => handleClick(4)} className={`${styles.dayBtn} ${currentDay === 4 ? styles.active : ''}`}>February 9th</button>
        <button onClick={() => handleClick(5)} className={`${styles.dayBtn} ${currentDay === 5 ? styles.active : ''}`}>February 10th</button>
        {/* <button onClick={() => handleClick(6)} className={`${styles.dayBtn} ${currentDay === 6 ? styles.active : ''}`}>February 11th</button> */}
      </div>

      {/* <h2>
        TBA
      </h2> */}
      
      <div className={styles.mobileBtn}>
        <FormControl
          fullWidth
          sx={{
            borderColor: "white",
            color: "white",
          }}
        >
          <InputLabel htmlFor="day-select" sx={{ color: "white" }} id="day-label">Day</InputLabel>
          <Select
            labelId="day-label"
            id="day-select"
            value={currentDay}
            defaultValue={0}
            label="Age"
            onChange={handleChange}
            sx={{
              borderColor: "white",
              color: "white",
              '.MuiOutlinedInput-notchedOutline': {
                borderColor: 'white',
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: 'white',
                borderWidth: '0.15rem',
              },
            }}
          >
            <MenuItem value={0}>February 5th</MenuItem>
            <MenuItem value={1}>February 6th</MenuItem>
            <MenuItem value={2}>February 7th</MenuItem>
            <MenuItem value={3}>February 8th</MenuItem>
            <MenuItem value={4}>February 9th</MenuItem>
            <MenuItem value={5}>February 10th</MenuItem>
            
          </Select>
        </FormControl>
      </div>

      <div className={styles.row}>
        {eventData.map((item) => (
          <EventCard
            key={`${item.title} ${item.time}`}
            title={item.title}
            time={item.time}
            location={item.location}
          />
        ))}
      </div>
      <h2>* Need to attend preceeding events</h2>
      <Footer className={styles.footer} />
    </div>
  )
}

export default Events